import { keyframes } from 'styled-components'

export const livePulse = keyframes`
	from {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
	}
	
	to {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
	}
`

export const animation_blur = () => (keyframes`
	from{
    filter: blur(0px)
  }
  to{
    filter: blur(3px)
  }
`)

export const animation_image = () => (keyframes`
	from{
    transform:translate3d(0,0px,0);
  }
  60%{
    transform:translate3d(0,5px,0);
  }
  to{
    transform:translate3d(0,0px,0);
  }
`)
import styled from 'styled-components'
import React from 'react';

import { BaseButton, IconContainer } from './Button'
import CloseSVG from '../../Svg/Close'

interface CLoseButtonProps {
  className?: string,
  onClick?: () => void,
  myDisabled?: boolean
}

export default ({ 
	className, 
  onClick,
  myDisabled
}: CLoseButtonProps) => {

  return (
    <CloseButton 
      type="button" 
      className={className} 
      onClick={onClick}
    >
      <IconContainer>
        <CloseSVG fill="#303030" width="25px" className="button-close" />
      </IconContainer>
    </CloseButton>

  )
}

const CloseButton = styled(BaseButton)`
  
`

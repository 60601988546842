import styled from "styled-components"
import React from "react"

import Button from "./Button"
import EmailIcon from "../../Svg/EmailIcon2"
import { mediaMin, mediaMax } from "../../../utils/breakpoints"

interface ContactButtonProps {
  className?: string
  onClick?: () => void
  active: boolean
  myDisabled?: boolean
}

export default ({
  className,
  onClick,
  active,
  myDisabled,
}: ContactButtonProps) => {
  return (
    <ContactButton
      type="button"
      className={className}
      onClick={onClick}
      icon={
        <EmailIcon
          fill="none"
          stroke="#303030"
          width="21px"
          className="icon"
        />
      }
      text="Hafa samband"
      active={active}
      myDisabled={myDisabled}
    />
  )
}

const ContactButton = styled(Button)``

import React from "react";
import { SVG } from '../../types/svg.ts'

export default ({
  height,
  width,
  style,
  className,
  fill,
  stroke
}: SVG) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 20"
      className={className}
      height={height}
      width={width}
      style={style}
      fill={fill}
      stroke={stroke}
    >
      <g strokeWidth="2" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" transform="translate(-44 -42)">
        <g transform="translate(30 30)">
          <g transform="translate(15 13)">
            <path d="M5 11.0952381L5 17.1666667 0 17.1666667 0 6.23809524 7.5 0.166666667 15 6.23809524 15 17.1666667 10 17.1666667 10 11.0952381z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export enum MyLocationEnum {
  _404LostAndAlone = "/404",
  Lobby = "/",
  Live = "/beint",
  Dagskra = "/dagskra",
  Speaker = "/fyrirlesari",
  Records = "/upptokur",

  Fordrykkur = "/fordrykkur",
  FyrstaHaed = "/fyrstahaed-bistro-facilities",
  OnnurHaed = "/onnurhaedogit",
  ThridjaHaed = "/thridjahaedogcrossindustry",
  Innova = "/innova",
  Innovation = "/innovationogcrossindustryservice",
  Framleidsla = "/framleidslaogframleidslustyring",
  Biosalur = "/biosalur",
  _File = "/leidbeiningar"
}

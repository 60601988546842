import React from "react";
import { SVG } from '../../types/svg.ts'

// width="18"
// height="18"

export default ({
	height,
	width,
	style,
	className,
	fill,
	stroke
}: SVG) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 18 18"
			className={className}
			height={height}
			width={width}
			style={style}
			fill={fill}
			stroke={stroke}
		>
			<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
				<g fill="#3B5998" fillRule="nonzero" transform="translate(-153 -208)">
					<g transform="translate(140 195)">
						<g transform="translate(13 13)">
							<path d="M16.313 0H1.688C.758 0 0 .76 0 1.688v14.624C0 17.241.76 18 1.688 18H9v-7.875H6.75v-2.25H9V6.75a3.38 3.38 0 013.375-3.375h2.25v2.25h-2.25c-.619 0-1.125.506-1.125 1.125v1.125h3.375l-.563 2.25H11.25V18h5.063C17.24 18 18 17.24 18 16.312V1.688C18 .76 17.24 0 16.312 0z"></path>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}
import React from "react";
import { SVG } from '../../types/svg.ts'

// width="21"
// height="20"

export default ({
  height,
  width,
  style,
  className,
  fill,
  stroke
}: SVG) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 21 20"
			className={className}
      height={height}
      width={width}
      style={style}
      fill={fill}
      stroke={stroke}
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      >
        <g stroke="#303030" strokeWidth="2" transform="translate(-42 -99)">
          <g transform="translate(30 85)">
            <g transform="translate(13 15)">
              <path d="M0 0.982142857L19 0.982142857 19 13.2221429 9.5 13.2221429 4.07142857 17.9821429 4.07142857 13.2221429 0 13.2221429z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
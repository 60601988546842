import React from "react";
import { SVG } from '../../types/svg.ts'

// width="24"
// height="24"

export default ({
	height,
	width,
	style,
	className,
	fill,
	stroke
}: SVG) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			className={className}
			height={height}
			width={width}
			style={style}
			fill={fill}
			stroke={stroke}
		>
			<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
				<g transform="translate(-40 -205)">
					<g transform="translate(30 195)">
						<g transform="translate(10 10)">
							<path d="M0 0L24 0 24 24 0 24z"></path>
							<circle
								cx="6"
								cy="12"
								r="3"
								stroke="#303030"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
							></circle>
							<circle
								cx="18"
								cy="6"
								r="3"
								stroke="#303030"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
							></circle>
							<circle
								cx="18"
								cy="18"
								r="3"
								stroke="#303030"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
							></circle>
							<path
								stroke="#303030"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M8.7 10.7L15.3 7.3"
							></path>
							<path
								stroke="#303030"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M8.7 13.3L15.3 16.7"
							></path>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}
import React from "react";
import { SVG } from '../../types/svg'

export default ({...props}: SVG) => {
  return (
  <svg 
    {...props}
    viewBox="0 0 24 24" 
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g id="24 / basic / check-mark">
      <path 
        id="icon" 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M9.70711 14.2929L19 5L20.4142 6.41421L9.70711 17.1213L4 11.4142L5.41421 10L9.70711 14.2929Z"
        fill="#98139C"
      />
      </g>
    </svg>
  );
}

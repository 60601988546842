import React from "react";
import { SVG } from '../../types/svg.ts'

// width="23"
// height="18"

export default ({
	height,
	width,
	style,
	className,
	fill,
	stroke
}: SVG) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 23 18"
			className={className}
			height={height}
			width={width}
			style={style}
			fill={fill}
			stroke={stroke}
		>
			<g
				fill="none"
				fillRule="evenodd"
				stroke="none"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1"
			>
				<g
					stroke="#303030"
					strokeWidth="2"
					transform="translate(-41 -153)"
				>
					<g transform="translate(30 140)">
						<g transform="translate(12 14)">
							<circle cx="11" cy="7.5" r="1"></circle>
							<circle cx="10.5" cy="8" r="4.5"></circle>
							<path d="M0 8S3.75.5 10.5.5 21 8 21 8s-3.75 7.5-10.5 7.5S0 8 0 8z"></path>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}


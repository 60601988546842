import React from "react"
import styled, { keyframes } from "styled-components"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import Arrow from "../../images/Arrow.svg"
import { MyLocationEnum } from "../Layout"

export const fadeInUp = () => keyframes`
	0% {
    transform: translateY(-60px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
`

interface MenuProps {
  myLocation?: MyLocationEnum
  onSameSiteClick?: () => void
}

export const Menu = ({ myLocation, onSameSiteClick }: MenuProps) => {
  return (
    <MenuList>
      {myLocation === MyLocationEnum.Live ? (
        <MenuItem onClick={onSameSiteClick}>Ballið</MenuItem>
      ) : (
        <MenuItem
          as={AniLink}
          cover
          to={"/beint"}
        >
          Ballið
        </MenuItem>
      )}

      {myLocation === MyLocationEnum.Dagskra ? (
        <MenuItem onClick={onSameSiteClick}>Dagskrá</MenuItem>
      ) : (
        <MenuItem
          as={AniLink}
          cover
          to={MyLocationEnum.Dagskra}
        >
          Dagskrá
        </MenuItem>
      )}

      {myLocation === MyLocationEnum.Fordrykkur ? (
        <MenuItem onClick={onSameSiteClick}>Fordrykkur</MenuItem>
      ) : (
        <MenuItem
          as={AniLink}
          cover
          to="/fordrykkur"
        >
          Fordrykkur
        </MenuItem>
      )}

      {myLocation === MyLocationEnum.Biosalur ? (
        <MenuItem onClick={onSameSiteClick}>Bíósalur</MenuItem>
      ) : (
        <MenuItem
          as={AniLink}
          cover
          to="/biosalur"
        >
          Bíósalur
        </MenuItem>
      )}

      {/* {myLocation === MyLocationEnum.Bransasogur ? (
        <MenuItem onClick={onSameSiteClick}>Bíósalur</MenuItem>
      ) : (
        <MenuItem
          as={AniLink}
          cover
          to="/herbergi"
        >
          Bíósalur
        </MenuItem>
      )} */}
      <MenuItem
        as={AniLink}
        cover
        to="/leidbeiningar"
      >
          Leiðbeiningar
        </MenuItem>
    </MenuList>
  )
}

const MenuList = styled.div`
  display: flex;
  flex-direction: column;
  opacity: 0;
  animation: 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.2s 1 normal forwards running ${fadeInUp};
`

const MenuItem = styled.button`
  color: #ffffff;
  font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 40px;
  text-decoration: none;
  position: relative;

  border: none;
  background: transparent;
  padding: 0;
  text-align: left;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:after {
    content: "";
    height: 40px;
    width: 25px;
    background-image: url(${Arrow});
    position: absolute;
    right: 0;
    background-repeat: no-repeat;
    background-position: 50%;
  }

  &.disabled {
    pointer-events: none;
    text-decoration: line-through;
  }
`

import styled from "styled-components"
import React from "react"

import Button from "./Button"
import HouseSVG from "../../Svg/House"
import { mediaMin, mediaMax } from "../../../utils/breakpoints"

interface HouseButtonProps {
  className?: string
  onClick?: () => void
  active: boolean
  myDisabled?: boolean
}

export default ({
  className,
  onClick,
  active,
  myDisabled,
}: HouseButtonProps) => {
  return (
    <HouseButton
      type="button"
      className={className}
      onClick={onClick}
      icon={
        <HouseSVG
          fill="none"
          stroke="#303030"
          width="17px"
          className="icon button-house"
        />
      }
      text="Heim"
      active={active}
      myDisabled={myDisabled}
    />
  )
}

const HouseButton = styled(Button)`
  @media ${mediaMax.tablet} {
    &.active,
    &:hover {
      margin-right: 65px !important;
    }
  }
`

import firebase from 'firebase/app';
import 'firebase/auth'; // importing the auth module as an example
import 'firebase/database'; // importing the auth module as an example

const config = {
  apiKey: "AIzaSyBfII9QemsNR8qdCnbFXZm1K8myBI3gkRY",
  authDomain: "marelradstefna.firebaseapp.com",
  databaseURL: "https://marelradstefna.firebaseio.com",
  projectId: "marelradstefna",
  storageBucket: "marelradstefna.appspot.com",
  messagingSenderId: "910504496114",
  appId: "1:910504496114:web:11ae774a75e36d4d6b796b"
}

let instance = null;

export default function getFirebase() {
  if (typeof window !== 'undefined') {
    if (instance) return instance;
    instance = firebase.initializeApp(config);
    return instance;
  }

  return null;
}
import styled, { keyframes } from "styled-components"
import React, { useEffect, useState } from "react"

import Button from "./Button"
import CommentRectSVG from "../../Svg/CommentRect"
import { mediaMin, mediaMax } from "../../../utils/breakpoints"

export const LOCALSTORAGE_HAS_CLICKED_CHAT = "hasClickedChat"

interface MessageButtonProps {
  className?: string
  onClick?: () => void
	active: boolean,
	text: string
}

export default ({
  className,
  onClick,
	active,
	text
}: MessageButtonProps) => {
  
  const [shakeInfinite, setShakeInfinite] = useState(false)

  const handleClick = () => {
    localStorage.setItem(LOCALSTORAGE_HAS_CLICKED_CHAT, "true");
    setShakeInfinite(false)
    onClick && onClick()
  }

  useEffect(() => {
    const hasClickedChat = localStorage.getItem(LOCALSTORAGE_HAS_CLICKED_CHAT)
    setShakeInfinite(hasClickedChat !== "true")
  }, [])

  const finalClassName = `${className} ${shakeInfinite ? 'shake-infinite' : ''}`
  return (
    <MessageButton
      type="button"
      className={finalClassName}
      onClick={handleClick}
      icon={
        <CommentRectSVG
          fill="none"
          stroke="#303030"
          width="21px"
          className="icon"
        />
      }
      text={text}
      active={active}
    />
  )
}

const shake = keyframes`
  0% { transform: translateX(0%); } 
   5% { transform: translateX(-2%) rotate(-1deg); } 
   10% { transform: translateX(2%) rotate(2deg); } 
   15% { transform: translateX(-1%) rotate(-3deg); } 
   20% { transform: translateX(1%) rotate(1deg); }
   25% { transform: translateX(-5%) rotate(-1deg); }
   28% { transform: translateX(0%) rotate(0deg); }
   100% { transform: translateX(0%); } 
`

const MessageButton = styled(Button)`
  border-radius: 22.5px;
	width: auto;
  margin-right: 10px;

  animation: ${shake} 2s;
  animation-iteration-count: 1;
  animation-delay: 1.5s;
  /* When the animation is finished, start again */

  &.shake-infinite {
    animation-iteration-count: infinite;
  }

	.icon-container {
		width: 45px;
    height: 45px;
    border-radius: 50%;
		position: initial;
	}

	.button-label {
		position: initial;
		transform: none;

		.button-label-content {
			padding: 0 22.5px 0 5px;
		}
	}
`

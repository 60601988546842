import styled from "styled-components"
import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink";

import Button, { BaseButton, IconContainer, ButtonLabel } from "./Button"
import BackSVG from "../../Svg/Back"
import { mediaMin, mediaMax } from "../../../utils/breakpoints"

interface FordrykkurButtonProps {
	className?: string
}

export default ({
	className,
}: FordrykkurButtonProps) => {
	return (
		<FordrykkurButton
			className={className}
			as={AniLink}
			to="/fordrykkur"
			cover
			direction="right"
		>
			<IconContainer>
				<BackSVG fill="#303030" width="25px" className="icon" />
			</IconContainer>

			<ButtonLabel text="Fordrykkur" />
		</FordrykkurButton>
	)
}

const FordrykkurButton = styled(BaseButton)`
	text-decoration: none;

	&:visited,
	&:focus {
		outline: none;
		color: #303030;
	}
`

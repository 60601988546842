import styled from "styled-components"
import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { mediaMin, mediaMax } from "../../../utils/breakpoints"
import Button, { BaseButton, IconContainer, ButtonLabel } from "./Button"

import BackSVG from "../../Svg/Back"

interface BackButtonProps {
  className?: string
  onClick?: () => void
  to?: string
  text?: string
  myDisabled?: boolean
}

export default ({
  className,
  onClick,
  to,
  text,
  myDisabled,
}: BackButtonProps) => {
  // Render link given a to property.
  if (to) {
    return (
      <BackLink
        to={to}
        fade
        as={AniLink}
        className={className}
        myDisabled={myDisabled}
      >
        <IconContainer>
          <BackSVG fill="#303030" width="25px" className="icon" />
        </IconContainer>

        <ButtonLabel text={text} />
      </BackLink>
    )
  }

  return (
    <BackButton
      type="button"
      className={className}
      onClick={onClick}
      icon={<BackSVG fill="#303030" width="25px" className="icon" />}
      text="Til baka"
      myDisabled={myDisabled}
    />
  )
}

const BackLink = styled(BaseButton)`
  text-decoration: none;

  &:visited,
  &:focus {
    outline: none;
    color: #303030;
  }
  @media ${mediaMax.tablet} {
    display: none;
  }
`

const BackButton = styled(Button)``

import React from "react";
import { SVG } from '../../types/svg.ts'

// width="20"
// height="18"

export default ({
	height,
	width,
	style,
	className,
	fill,
	stroke
}: SVG) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 20 18"
			className={className}
			height={height}
			width={width}
			style={style}
			fill={fill}
			stroke={stroke}
		>
			<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
				<g fill="#00ACEE" fillRule="nonzero" transform="translate(-98 -209)">
					<g transform="translate(85 195)">
						<g transform="translate(13 14.5)">
							<path d="M20 2.392a8.172 8.172 0 01-2.354.635A4.075 4.075 0 0019.45.792c-.792.461-1.672.8-2.604.98A4.13 4.13 0 0013.85.5c-2.265 0-4.1 1.808-4.1 4.038 0 .315.035.623.105.92A11.71 11.71 0 011.4 1.234a3.994 3.994 0 00-.555 2.03c0 1.4.722 2.639 1.823 3.361a4.174 4.174 0 01-1.86-.503v.05c0 1.958 1.414 3.593 3.292 3.962a4.172 4.172 0 01-1.856.07 4.107 4.107 0 003.832 2.807A8.311 8.311 0 010 14.685 11.736 11.736 0 006.29 16.5c7.545 0 11.674-6.157 11.674-11.496 0-.177-.003-.35-.011-.523a8.167 8.167 0 002.046-2.088L20 2.392z"></path>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}
import React, {
  ReactNode,
  useState,
  useEffect,
  useContext,
} from "react"
import styled, { ThemeProvider } from "styled-components"
import { theme, GlobalStyle } from '../../styles'


import Header from "../Header"
import Lobby from "../Lobby"
import useLocationCondition from "./hooks/useLocationCondition"
import { MyLocationEnum } from "./utils/enum"
import { ESC } from "../../utils/keyCodes"
import ContactForm from "../ContactForm/ContactForm"
import CookieConsent from "../CookieConsent"

import DynamicContext from "../../context"
import CloseButton from "../Header/components/CloseButton"
import { PageProps } from "gatsby"
import { LocationConditionOptions } from "./types"
import { TransitionStatus } from "react-transition-group/Transition"


interface LayoutProps {
  children: ReactNode
  myLocation: MyLocationEnum
  location: PageProps
  onInfo?: () => void
  transitionStatus?: TransitionStatus
  onClose?: () => void
  onEye?: () => void
  infoText?: string
  showClose?: boolean,
  showInfo?:boolean,
  showMessage?: boolean
  className?: string
}

export default ({
  children,
  myLocation,
  onInfo,
  location,
  onClose,
  showInfo,
  infoText,
  onEye,
  showMessage,
  className
}: LayoutProps) => {

  const { liveSpeaker } = useContext(DynamicContext)

  const [showLobby, setShowLobby] = useState(false)
  const [showContactForm, setShowContactForm] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  const handleToggleContact = () => {
    const nextToggle = !showContactForm
    setShowContactForm(nextToggle)
    nextToggle && setShowLobby(false)
  }

  let options = useLocationCondition({ liveSpeaker, myLocation })

  const { alwaysShowLobby, liveButtonActive } = options

  const handleBack = () => {
    // TODO: This is not optimal
    if (typeof history !== undefined) {
      history.back()
    }
  }

  const handleClose = () => {
    onClose ? onClose() : null
    setShowLobby(false)
    setShowContactForm(false)
  }

  useEffect(() => {
    const shouldListen = showLobby && !alwaysShowLobby

    if (typeof window !== undefined) {
      setIsLoggedIn(localStorage.getItem("loggedIn") != undefined)
    }

    const handleKeydown = (event: KeyboardEvent) => {
      if (event.keyCode === ESC) {
        setShowLobby(false)
      }
    }

    if (shouldListen) {
      document.addEventListener("keydown", handleKeydown, false)
    }

    return () => {
      document.removeEventListener("keydown", handleKeydown, false)
    }
  }, [showLobby, alwaysShowLobby])

  const [infoOpen, setInfoOpen] = useState(false)
  const toggleInfoOpen = () => {
    setInfoOpen(!infoOpen)
    onInfo && onInfo()
  }
  const handleInfoOpen = (open:boolean) => {
    setInfoOpen(open);
  }

  const [messageOpen, setMessageOpen] = useState(false)
  const toggleMessageOpen = () => {
    setMessageOpen(!messageOpen)
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />

      <Container className={className}>

        <Header
          {...options}
          showMessage={showMessage}
          onHome={() => setShowLobby(!showLobby)}
          onBack={handleBack}
          homeActive={showLobby || alwaysShowLobby || !isLoggedIn}
          liveActive={liveButtonActive}
          onInfo={toggleInfoOpen}
          infoActive={infoOpen}
          onClose={handleClose}
          contactActive={showContactForm}
          onContact={handleToggleContact}
          onEye={onEye}
          onMessage={toggleMessageOpen}
        />

        <Lobby
          show={showLobby || alwaysShowLobby || !isLoggedIn}
          onCloseLobby={() => setShowLobby(false)}
          myLocation={myLocation}
        />

        <ContactForm
          show={showContactForm}
          onClose={() => setShowContactForm(false)}
          location={location}
        />

        <CookieConsent />

        <RoomOverlay className={showInfo ? "show" : ""}>
          <div className="text">
            {infoText}
            <ContactButtonInOverlay onClick={() => setShowContactForm(true)}>
              Nánari upplýsingar
            </ContactButtonInOverlay>
          </div>
          <CloseOverlayButton onClick={handleClose}></CloseOverlayButton>
        </RoomOverlay>

        <Main>
          {typeof children === "function" ? children({ infoOpen, messageOpen, setMessageOpen, handleInfoOpen }) : children}
        </Main>

      </Container>
    </ThemeProvider>
  )
}

const CloseOverlayButton = styled(CloseButton)`
  position:absolute;
  top:30px;
  right: 165px;
`

const ContactButtonInOverlay = styled.button`
  display: block;
  height: 45px;
  padding: 0 15px;
  border-radius: 22.5px;
  outline: none;
  border: none;
  font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
  margin-top: 10px;
`

const RoomOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.6s ease;

  .text {
    color: #ffffff;
    font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 36px;
    max-width: 55vw;
    width: 100%;
    padding: 0 30px;
  }

  &.show {
    opacity: 1;
    pointer-events: auto;
    z-index: 200;
  }
`

const Container = styled.div`
  overflow: hidden;
  /* transform: translate(0px, 0px); */

  /*  */
`

const Main = styled.div`
  position: relative;
  min-height: 100vh;
  width: 100vw;
  transform: none;
  /* transition: transform .5s cubic-bezier(.59,.01,.28,1); */
`

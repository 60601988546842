import styled from 'styled-components'
import React from 'react';

import Button from './Button'
import EyeSVG from '../../Svg/Eye'

interface EyeButtonProps {
  className?: string,
  onClick?: () => void,
  myDisabled?: boolean,
}

export default ({ 
	className, 
  onClick,
  myDisabled
}: EyeButtonProps) => {

  return (
    <EyeButton 
      type="button" 
      className={className} 
      onClick={onClick}
      icon={<EyeSVG fill="none" stroke="#303030" width="21px" className="icon" />}
      text="Hreyfing"
      myDisabled={myDisabled}
    />
  )
}

const EyeButton = styled(Button)`
  
`


import { encode } from './utils'

export const FORM_NAME = "contact"

export default ({
	name,
	email,
	message,
	pathname,
}) => {
	
	return new Promise((resolve, reject) => {
		fetch("/", {
			method: "POST",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: encode({ 
				"form-name": FORM_NAME, 
				name,
				email,
				message,
				["website-page"]: pathname,
			})
		})
			.then(resolve)
			.catch(reject);
	})
}
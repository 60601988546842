import React from "react";
import { SVG } from '../../types/svg.ts'

// width="23"
// height="25"

export default ({
	height,
	width,
	style,
	className,
	fill,
	stroke
}: SVG) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 23 25"
			className={className}
			height={height}
			width={width}
			style={style}
			fill={fill}
			stroke={stroke}
		>
			<g
				fill="none"
				fillRule="evenodd"
				stroke="none"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1"
			>
				<g stroke="#303030" strokeWidth="2" transform="translate(-41 -150)">
					<g transform="translate(30 30)">
						<g transform="translate(0 110)">
							<g transform="translate(12 11.543)">
								<path d="M10.5 8.857v6.6m0-9.5v-.034"></path>
								<circle cx="10.5" cy="10.957" r="10.5"></circle>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}
import React, { ReactNode } from "react"
import styled from "styled-components"
import { mediaMin, mediaMax } from "../../../utils/breakpoints"

interface ButtonLabelProps {
  children?: ReactNode
  text?: string
}
export const ButtonLabel = ({ children, text }: ButtonLabelProps) => {
  const useChildren = React.Children.count(children) > 0
  return (
    <div className="button-label">
      <div className="button-label-content">
        {useChildren ? (
          children
        ) : (
          <span className="button-label-text">{text}</span>
        )}
      </div>
    </div>
  )
}

interface ButtonProps {
  icon: ReactNode
  text?: string
  className?: string
  onClick?: () => void
  type?: "button" | "submit" | "reset"
  children?: ReactNode
  active?: boolean
  myDisabled?: boolean
}
export default ({
  icon,
  text,
  className,
  type,
  children,
  active,
  onClick,
  myDisabled,
}: ButtonProps) => {
  const activeClass = active ? "active" : ""
  const disabledClass = myDisabled ? "disable" : ""

  return (
    <StyledButton
      type={type}
      className={`${className} ${activeClass} ${disabledClass}`}
      onClick={onClick}
    >
      <IconContainer className="icon-container">{icon}</IconContainer>

      <ButtonLabel text={text}>{children}</ButtonLabel>
    </StyledButton>
  )
}

export const BaseButton = styled.button`
  position: relative;
  display: block;
  min-width: 0;
  padding: 0;
  border: 0;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  width: 45px;
  height: 45px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  border-radius: 50%;
  opacity: 1;
  transition: opacity 0.45s cubic-bezier(0.65, 0, 0.076, 1);

  &:focus {
    outline: none;
  }

  &.disable {
    opacity: 0.2;
    pointer-events: none;
  }

  .button-label {
    position: absolute;
    z-index: 10;
    height: 100%;
    left: 22.5px;
    overflow: hidden;
    pointer-events: none;
  }

  .button-label-content {
    border-radius: 0px 22.5px 22.5px 0px;
    padding: 0 22.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #303030;
    background: white;
    height: 100%;
    transform: translate3d(-100%, 0, 0);
    transition: transform 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  }

  .button-label-text {
    opacity: 0;
    transition: opacity 0.2s cubic-bezier(0.65, 0, 0.076, 1) translateZ(0);
    backface-visibility: hidden;
    transform: translateZ(0);
    color: #303030;
    font-family: ${({theme}) => theme.fonts.fontFamilySecondary};
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    text-transform: uppercase;
  }

  &:hover,
  &.active {
    .button-label {
      pointer-events: auto;
    }

    .button-label-content {
      transform: translate3d(0, 0, 0);
      transition: transform 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    }

    .button-label-text {
      opacity: 1;
      transform: translateZ(0);
      transition: opacity 0.45s cubic-bezier(0.65, 0, 0.076, 1) 0.2s;
    }
  }
`

export const IconContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;

  > svg {
    background: white;
  }
`

const StyledButton = styled(BaseButton)`
  &:focus {
    outline: 0;
  }
`

import React from "react"
import styled from "styled-components"
import { mediaMin, mediaMax } from "../../utils/breakpoints"

import BackButton from "./components/BackButton"
import HouseButton from "./components/HouseButton"
import CloseButton from "./components/CloseButton"
import GoLiveButton from "./components/GoLiveButton"
import ContactButton from "./components/ContactButton"
import EyeButton from "./components/EyeButton"
import SocialButton from "./components/SocialButton"
import MessageButton from './components/MessageButton'
import InfoButton from "./components/InfoButton"
import FordrykkurButton from './components/FordrykkurButton'
import { LocationConditionOptions } from "../Layout/types"

interface HeaderProps extends LocationConditionOptions {
  className?: string
  showMessage?:boolean
  onBack?: () => void
  onHome?: () => void
  onGoLive?: () => void
  onClose?: () => void
  onInfo?: () => void
  onEye?: () => void
  onMessage?: () => void

  onContact?: () => void
  homeActive?: boolean
  liveActive?: boolean
  contactActive?: boolean
  infoActive?: boolean
}

export default ({
  className,
  onBack,
  onHome,
  onGoLive,
  onClose,
  onInfo,
  onContact,
  onEye,
  onMessage,
  showMessage,

  disabledHome,
  disabledBack,
  disabledGoLive,
  disabledClose,
  disabledSocial,
  disabledContact,
  disabledEye,
  disabledInfo,

  homeActive,
  liveActive,
  contactActive,
  showHome,
  showInfo,
  showBack,
  showGoLive,
  showClose,
  showSocial,
  showContact,
  showEye,
  backTo,
  backText,
  eyeTo,
  infoText,
  infoTo,
  infoActive,
  showFordrykkur,
}: HeaderProps) => {
  return (
    <Header className={className}>
      <LeftNavigation>
        {showBack && (
          <BackButton
            myDisabled={disabledBack}
            className="button"
            onClick={onBack}
            to={backTo}
            text={backText}
          />
        )}
        {showHome && (
          <HouseButton
            myDisabled={disabledHome}
            className="button"
            onClick={onHome}
            active={homeActive}
          />
        )}

        {showFordrykkur && (
          <FordrykkurButton className="button" />
        )}

        {showContact && (
          <ContactButton
            myDisabled={disabledContact}
            className="button"
            onClick={onContact}
            active={contactActive}
          />
        )}
        {showSocial && (
          <SocialButton myDisabled={disabledSocial} className="button" />
        )}
        {showInfo && (
          <InfoButton
            myDisabled={disabledInfo}
            onClick={onInfo}
            text={infoText}
            to={infoTo}
            active={infoActive}
            className="button"
          />
        )}
        {showEye && (
          <EyeButton
            onClick={onEye}
            myDisabled={disabledEye}
            className="button"
          />
        )}
      </LeftNavigation>

      <Spacer />

      <RightNavigation>
        {showMessage && (
          <MessageButton
            className="button"
            onClick={onMessage}
            active={true}
            text="Spjall"
          />
        )}
        {showGoLive && (
          <GoLiveButton
            className="button"
            onClick={onGoLive}
            isActive={liveActive}
          />
        )}
        {showClose && (
          <CloseButton
            myDisabled={disabledClose}
            className="button"
            onClick={onClose}
          />
        )}
      </RightNavigation>
    </Header>
  )
}

const Header = styled.header`
  position: fixed;
  left: 0;
  z-index: 400;
  width: 100%;
  /* TODO: one size */
  top: 30px;
  width: 100%;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
`

const Spacer = styled.div`
  flex: 1;
`

const LeftNavigation = styled.nav`
  display: flex;
  flex-direction: column;
  pointer-events: auto;

  .button:not(:first-child) {
    margin-top: 10px;
  }
  @media ${mediaMax.tablet} {
    flex-direction: row;

    .button {
      margin-right: 15px;
      margin-top: 0;
      transition: margin-right 0.5s;

      &:not(:first-child) {
        margin-top: 0;
      }
      &.active {
        margin-right: 65px;
      }
    }
  }
`

const RightNavigation = styled.nav`
  display: flex;
  pointer-events: auto;
  height:100%;
  .button:nth-child(2) {
    margin-left: 10px;
  }
`

import React, { useState, useEffect } from "react"
import { MyLocationEnum } from "../utils/enum"
import { LocationConditionOptions } from "../types"

interface UseLocationConditionProps {
  myLocation: MyLocationEnum
  informationPath?: string
  liveSpeaker: any
}
export default ({
  myLocation,
  informationPath,
  liveSpeaker,
}: UseLocationConditionProps): LocationConditionOptions => {

  let options: LocationConditionOptions = {
    showInfo: false,
    showHome: false,
    showBack: false,
    showGoLive: false,
    showClose: false,
    showSocial: false,
    showContact: true,
    showEye: false,
    alwaysShowLobby: false,
    liveButtonActive: false,
  }

  const roomOptions: LocationConditionOptions = {
    showHome: true,
    showGoLive: true,
    showFordrykkur: true,
  }
  // TODO: What is visible here ?
  const roomOptionsMobile: LocationConditionOptions = {
    showHome: true,
    showGoLive: true,
  }

  const isLiveSpeaker = liveSpeaker !== null
  const createLiveSpeakerSlug = (slug: string) => {
    if (slug) {
      return `${MyLocationEnum.Speaker}/${slug}`
    }
    return ""
  }
  const [showMobile, setShowMobile] = useState(false)
  useEffect(() => {
    const resp = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    setShowMobile(resp)
  }, [])

  if (myLocation === MyLocationEnum.Live) {
    return {
      ...options,
      showHome: true,
      showGoLive: true,
      liveButtonActive: true,
      showSocial: false,
      showInfo: isLiveSpeaker,
      infoTo: isLiveSpeaker && createLiveSpeakerSlug(liveSpeaker.slug),
    }
  }
  else if (myLocation === MyLocationEnum.Lobby) {
    return {
      ...options,
      showGoLive: true,
      alwaysShowLobby: true,
      showContact: true,
      showSocial: false,
    }
  }
  else if (myLocation === MyLocationEnum.Records) {
    return {
      ...options,
      showHome: true,
      showGoLive: false,
      showSocial: false,
      showContact: false,
    }
  }
  else if (myLocation === MyLocationEnum.Dagskra) {
    return {
      ...options,
      showHome: true,
      showGoLive: true,
      showSocial: false,
    }
  }
  else if (myLocation === MyLocationEnum.Speaker) {
    return {
      ...options,
      showHome: true,
      showGoLive: true,
      showSocial: false,
    }
  }
  else if (myLocation === MyLocationEnum._404LostAndAlone) {
    return {
      ...options,
      showHome: true,
      showGoLive: true,
      showSocial: false,
      showContact: true,
      showBack: true,
    }
  }
  else if (myLocation === MyLocationEnum.Biosalur) {
    return {
      ...options,
      showHome: true,
      showGoLive: true,
      showSocial: false,
      showContact: true,
      showBack: false,
    }
  }else if (myLocation === MyLocationEnum._File) {
    return {
      ...options,
      showHome: true,
      showGoLive: true,
      showSocial: false,
      showContact: false,
    }
  }

  /* 
   * Rooms 
  */
  else if (myLocation === MyLocationEnum.Fordrykkur) {
    if (!showMobile) {
      return {
        ...options,
        showHome: true,
        showGoLive: true,
        showSocial: false,
      }
    }
    else {
      return {
        ...options,
        showHome: true,
        showGoLive: true,
        showSocial: false,
      }
    }
  }
  else if (
    myLocation === MyLocationEnum.FyrstaHaed
    || myLocation === MyLocationEnum.OnnurHaed
    || myLocation === MyLocationEnum.ThridjaHaed
    || myLocation === MyLocationEnum.Innova
    || myLocation === MyLocationEnum.Innovation
    || myLocation === MyLocationEnum.Framleidsla
  ) {
    if (!showMobile) {
      return {
        ...options,
        ...roomOptions,
      }
    }
    else {
      return {
        ...options,
        ...roomOptionsMobile
      }
    }
  }


  return options
}
